import request from '@/utils/request2'

// 获取当日报工数据接口：api/
export function getCurDayBgData (params) {
  return request({
    url: '/api/wxjj/getCurDayBgData',
    method: 'get',
    params: params
  })
}

// 自动掉落件-工件下拉项目
export function getBggjList (params) {
  return request({
    url: '/api/wxjj/getBggjList',
    method: 'get',
    params: params
  })
}

// 自动掉落件-工序下拉项目
export function getBggxList (params) {
  return request({
    url: '/api/wxjj/getBggxList',
    method: 'get',
    params: params
  })
}

// 提交报工数量 
export function postOneToolsNum (params) {
  return request({
    url: '/api/wxjj/postOneToolsNum',
    method: 'get',
    params: params
  })
}

// 获取历史报工数据接口（默认显示当天）
export function getHisEmpBgList (params) {
  return request({
    url: '/api/wxjj/getHisEmpBgList',
    method: 'get',
    params: params
  })
}

// 自动掉落件-提交审核接口
export function postEmpDljBg (params) {
  return request({
    url: '/api/wxjj/postEmpDljBg',
    method: 'get',
    params: params
  })
}

// 修改历史报工数据接口
export function saveHisXgBg (params) {
  return request({
    url: '/api/wxjj/saveHisXgBg',
    method: 'get',
    params: params
  })
}

//------------审核
// 获取默认计产类别接口
export function getDefaultJxType (params) {
  return request({
    url: '/api/wxjj/getDefaultJxType',
    method: 'get',
    params: params
  })
}

// 获取待审核数据
export function getForCheckBgList (params) {
  return request({
    url: '/api/wxjj/getForCheckBgList',
    method: 'get',
    params: params
  })
}

// 批量审核
export function batchCheckBgList (data) {
  return request({
    url: '/api/wxjj/batchCheckBgList',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-datacharset=UTF-8' }
  })
}

// 获取历史审核记录接口
export function getHisHasCheckList (params) {
  return request({
    url: '/api/wxjj/getHisHasCheckList',
    method: 'get',
    params: params
  })
}

// 修改单笔报工记录数量
export function saveBgNum (params) {
  return request({
    url: '/api/wxjj/saveBgNum',
    method: 'get',
    params: params
  })
}

// 首页点击按钮接口
export function beforeClickBtn (params) {
  return request({
    url: '/api/wxjj/beforeClickBtn',
    method: 'get',
    params: params
  })
}

// 获取当天集体报工数据接口
export function getCurDayGroupBgList (params) {
  return request({
    url: '/api/wxjj/getCurDayGroupBgList',
    method: 'get',
    params: params
  })
}

// 集体报工提交接口
export function postGroupBg (params) {
  return request({
    url: '/api/wxjj/postGroupBg',
    method: 'get',
    params: params
  })
}

// 获取集体报工历史数据接口
export function getHisGroupBgList (params) {
  return request({
    url: '/api/wxjj/getHisGroupBgList',
    method: 'get',
    params: params
  })
}

// 修改历史报工数据接口
export function saveHisGroupBg (params) {
  return request({
    url: '/api/wxjj/saveHisGroupBg',
    method: 'get',
    params: params
  })
}

// 获取当月工作时长记录接口
export function getCurMonthWorkTimeMaster (params) {
  return request({
    url: '/api/wxjj/getCurMonthWorkTimeMaster',
    method: 'get',
    params: params
  })
}

// 提交工时数据接口
export function postWorkTimeData (params) {
  return request({
    url: '/api/wxjj/postWorkTimeData',
    method: 'get',
    params: params
  })
}

// 获取人员明细 
export function getWorkTimeEmpList (params) {
  return request({
    url: '/api/wxjj/getWorkTimeEmpList',
    method: 'get',
    params: params
  })
}

// 提交人员工时明细接口
export function postWorkTimeDetail (data) {
  return request({
    url: '/api/wxjj/postWorkTimeDetail',
    method: 'post',
    data: data
  })
}

// 修改单笔待审核记录的合格数量 
export function saveForCheckHgNum (params) {
  return request({
    url: '/api/wxjj/saveForCheckHgNum',
    method: 'get',
    params: params
  })
}